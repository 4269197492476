import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter} from "react-router-dom";

const importBuildTarget = () => {
  if (process.env.REACT_APP_BUILD_TARGET === 'app') {
    return import('./App');
  } else if (process.env.REACT_APP_BUILD_TARGET === 'demo') {
    return import ('./Demo')
  } else {
    return Promise.reject(
      new Error("No such build target: " + process.env.REACT_APP_BUILD_TARGET)
    )
  }
}

importBuildTarget()
  .then(({default: Environment}) =>
    ReactDOM.render(
      process.env.REACT_APP_BUILD_TARGET === 'app' ? <BrowserRouter><Environment/></BrowserRouter> :
        <Environment/>, document.getElementById('root')
    ))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
